import { Suspense } from "react";
import {
  Outlet,
  useParams,
  defer,
  Await,
  useLoaderData,
} from "react-router-dom";
import OrganisationNav from "../../../components/OrganisationNav";
import Details from "../components/Details";
import { Appwrite } from "../../../services";
import {
  DATABASE_ID,
  ORGANISATIONS_COLLECTION_ID,
} from "../../../data/constants";
import OrganisationDetailsSkeleton from "../components/OrganisationDetailsSkeleton";

const appwrite = new Appwrite();
function OrganisationLayout() {
  const { organisation } = useParams();
  const data = useLoaderData();
  return (
    <div className="wrapper">
      <div className="content">
        <Suspense fallback={<OrganisationDetailsSkeleton />}>
          <Await resolve={data.organisationsDetails}>
            {(organisationDetails) => {
              return <Details organisationDetails={organisationDetails} />;
            }}
          </Await>
        </Suspense>
        <OrganisationNav organisation={organisation} />
      </div>
      <div className="flex mt-2 border h-4/5 border-red-500">
        <Outlet />
      </div>
    </div>
  );
}

export const loader = async ({ params }) => {
  const organisation = params.organisation;
  const organisationPromise = appwrite.getDocument(
    DATABASE_ID,
    ORGANISATIONS_COLLECTION_ID,
    organisation
  );
  return defer({
    organisationsDetails: organisationPromise,
  });
};

export default OrganisationLayout;
