export const API_ENDPOINT = process.env.REACT_APP_APPWRITE_API_ENDPOINT;
export const PROJECT_ID = process.env.REACT_APP_APPWRITE_API_PROJECT_ID;
export const DATABASE_ID = process.env.REACT_APP_APPWRITE_DATABASE_ID;
export const ORGANISATIONS_COLLECTION_ID =
  process.env.REACT_APP_APPWRITE_ORG_COLLECTION_ID;
export const SYSTEM_TEAM_ID = process.env.REACT_APP_APPWRITE_SYSTEM_TEAM_ID;
export const LOGOS_BUCKET_ID = process.env.REACT_APP_APPWRITE_LOGOS_BUCKET_ID;
export const RESOURCES_BUCKET_ID =
  process.env.REACT_APP_APPWRITE_RESOURCES_BUCKET_ID;
export const USER_MEMBERSHIP_LISTING_FUNCTION_ID =
  process.env.REACT_APP_APPWRITE_USER_MEMBERSHIP_LISTING_FUNCTION_ID;
