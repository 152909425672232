import { Formik, Form, Field, ErrorMessage } from "formik";
import TextAreaField from "../../../../../../components/form/TextAreaField";
import TextInputField from "../../../../../../components/form/TextInputField";
import useForm from "../../../../hooks/useForm";
import api from "../../../../../../components/helpers/appwrite/api";
import { programStepOneValidationSchema } from "../../../../../../components/helpers/validator";
import { RiDeleteBin6Line } from "react-icons/ri";

function Step1() {
  const { initialValues, setInitialValues, setActiveStepIndex } = useForm();
  const handleSubmit = async (values, { resetForm }) => {
    if (values?.programType === "other") {
      // Upload the thumbnail
      // Generate the thumbnail url
      // Update the thumbnail in the values object
      // Update the thumbnail url in the values object
      // Create the program
      // Reset the form

      const thumbnail = await api.createProgramResource(values?.thumbnail);
    } else {
      setInitialValues({ ...initialValues, ...values, programType: "course" });
      setActiveStepIndex(1);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={programStepOneValidationSchema}
      enableReinitialize
    >
      {({ values, touched, errors, setFieldValue, handleBlur }) => (
        <Form>
          <h1 className="text-sm font-light text-gray-800">
            Specify the program details
          </h1>
          <TextInputField label="Name" name="name" id="name" mandatory={true} />
          <TextAreaField
            label="Description"
            name="description"
            id="description"
            mandatory={true}
          />
          <div className="w-96 py-2">
            <div className={`text-sm font-semibold mb-1`}>
              Thumbnail{" "}
              <span className="text-sm font-semibold mx-1 text-red-700">*</span>
            </div>
            <label
              htmlFor="thumbnail"
              className={`px-1 cursor-pointer flex border rounded-md items-center justify-between border-slate-200 bg-gray-100 focus:bg-white ${
                touched?.thumbnail && errors?.thumbnail
                  ? "border-1 border-red-700 bg-red-100"
                  : ""
              }`}
            >
              <div className="flex items-center">
                <span className="bg-gray-900 text-white my-0.5 px-2 py-1.5 rounded-full text-xs">
                  Upload
                </span>
                {
                  <span
                    className={`mx-2 text-sm font-light ${
                      initialValues?.thumbnail?.name
                        ? "text-black"
                        : touched?.thumbnail && errors?.thumbnail
                        ? "text-red-700"
                        : ""
                    }`}
                  >
                    {initialValues?.thumbnail?.name ?? "No file chosen"}
                  </span>
                }
              </div>
              {initialValues?.thumbnail?.name && (
                <div className="flex items-center">
                  <button
                    type="button"
                    className="text-red-700"
                    onClick={(event) => {
                      event.preventDefault();
                      setInitialValues({
                        ...initialValues,
                        thumbnail: null,
                        thumbnailUrl: "",
                      });
                      setFieldValue("thumbnail", null);
                      setFieldValue("thumbnailUrl", "");
                      console.log("Initial Values: ", initialValues);
                    }}
                  >
                    <RiDeleteBin6Line />
                  </button>
                </div>
              )}
              <input
                type="file"
                className="hidden"
                name="thumbnail"
                id="thumbnail"
                onChange={(event) => {
                  console.log("File: ", event.target.files[0]);
                  if (event?.target?.files?.[0]) {
                    setInitialValues({
                      ...values,
                      thumbnail: event.target.files[0],
                    });

                    const reader = new FileReader();
                    reader.onload = () => {
                      setFieldValue("thumbnailUrl", reader.result);
                    };
                    reader.readAsDataURL(event.target.files[0]);
                    setFieldValue("thumbnail", event.target.files[0]);
                    setFieldValue("thumbnailUrl", "");
                  }
                }}
                onBlur={() => {
                  if (!initialValues?.thumbnail?.name) {
                    setFieldValue("thumbnail", null);
                    setFieldValue("thumbnailUrl", "");
                  }
                  return;
                }}
              />
            </label>
            <ErrorMessage name="thumbnail">
              {(msg) => (
                <div className="font-light text-xs text-red-700 mt-l mt-1">
                  {msg}
                </div>
              )}
            </ErrorMessage>
          </div>

          <div className="w-96 py-2">
            <label className="w-full text-sm text-black font-semibold">
              Type
              <span className="text-sm font-semibold mx-1 text-red-700">*</span>
            </label>
            <div>
              <label className="text-sm mx-1">
                <Field
                  type="radio"
                  name="programType"
                  value="course"
                  className="mr-1"
                  onChange={() => {
                    setInitialValues({ ...values, programType: "course" });
                    console.log("Values: ", values);
                  }}
                />
                Course
              </label>
              <label className="text-sm mx-1">
                <Field
                  type="radio"
                  name="programType"
                  value="other"
                  className="mr-1"
                  onChange={() => {
                    setInitialValues({ ...values, programType: "other" });
                    console.log("Values: ", values);
                  }}
                />
                Other
              </label>
            </div>
          </div>
          {/* 
            Remove default placeholder (dd/mm/yyyy) from date input field tailwindcss
            https://stackoverflow.com/questions/66954337/remove-default-placeholder-dd-mm-yyyy-from-date-input-field-tailwindcss  
          */}
          <TextInputField
            label="Start Date"
            name="startDate"
            id="name"
            type="date"
            mandatory={true}
          />
          <TextInputField
            label="End Date"
            name="endDate"
            id="name"
            type="date"
            mandatory={true}
          />

          <div className="text-right mt-2">
            <button
              type="submit"
              className="text-white text-sm font-semi bg-orange-400 capitalize hover:bg-orange-500 transition-all duration 200 text-md px-1.5 py-1 rounded-sm ease-in"
            >
              {values?.programType === "course" ? "Next" : "Create"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Step1;
