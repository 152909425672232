import {
  Link,
  useParams
} from 'react-router-dom';

export default function Details() {
  let { program } = useParams();

  return (
    <div className="wrapper">
      <div className="content">
        <Link to="/organisations/9005/programs">All</Link> | <Link to="/organisations/9005/programs/new">Add New</Link><br/> | <Link to="/organisations/9005/programs/1234/participants">Participants</Link><br/>
        <h1>Program {program}</h1>
      </div>
    </div>
  );
}