import { Link } from "react-router-dom";
import { getInitials } from "../../../components/helpers/getInitials";
import { CiLocationOn } from "react-icons/ci";
import { HiLink } from "react-icons/hi";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineTwitter } from "react-icons/ai";

export default function Details({ organisationDetails }) {
  return (
    <header className="mb-5 flex gap-2">
      <div className="h-24 w-24 border rounded-md overflow-hidden">
        {organisationDetails?.logoUrl ? (
          <img
            className="w-full h-full"
            alt={getInitials(organisationDetails?.name ?? "logo")}
            src={organisationDetails.logoUrl}
          />
        ) : (
          <div className="flex justify-center items-center text-white text-3xl font-bold bg-orange-500 w-full h-full">
            {getInitials(organisationDetails?.name ?? "Organisation")}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between py-2 mx-2">
        <p className="font-semibold text-2xl">{organisationDetails?.name}</p>
        <p
          className={`font-light text-sm text-gray-600 ${
            !organisationDetails?.description ? "invisible" : ""
          }`}
        >
          {organisationDetails?.description ?? "Description here"}
        </p>
        <div className="flex gap-5">
          {organisationDetails?.location && (
            <div className="flex items-center font-light gap-1">
              <CiLocationOn size={22} />
              <span className="text-sm font-light text-gray-500">
                {" "}
                {organisationDetails?.location ?? "Uganda"}
              </span>
            </div>
          )}
          {organisationDetails?.website && (
            <div className="flex items-center font-light gap-1">
              <HiLink size={22} />
              <Link
                to={organisationDetails?.website || "https://ablestate.co"}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm font-light text-gray-500 hover:underline cursor-pointer"
              >
                {" "}
                {organisationDetails?.website ?? "https://ablestate.co"}
              </Link>
            </div>
          )}
          {organisationDetails?.email && (
            <div className="flex items-center font-light gap-1">
              <MdOutlineMailOutline size={22} />
              <Link
                to={`mailto:${
                  organisationDetails?.email ?? "ablestate@gmail.com"
                }`}
                className="text-sm font-light text-gray-500 hover:underline cursor-pointer"
              >
                {" "}
                {organisationDetails?.email ?? "ablestate@gmail.com"}
              </Link>
            </div>
          )}
          {organisationDetails?.twitter && (
            <div className="flex items-center font-light gap-1">
              <AiOutlineTwitter size={22} />
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm font-light text-gray-500 hover:underline hover:text-blue-500 cursor-pointer"
              >
                {" "}
                {organisationDetails?.twitter ?? "@theablestate"}
              </Link>
            </div>
          )}
          {/* This is just a place holder for the entire contacts just for space */}
          <div className="invisible">{" contacts "}</div>
        </div>
      </div>
    </header>
  );
}
