import React from 'react'
import { useNavigate } from 'react-router-dom';

function UnAuthorized() {
  const navigate = useNavigate();
  return (
    <div>
      <h1>UnAuthorized</h1>
      <button onClick={()=> navigate(-1)}>Go Back</button>
    </div>
  )
}

export default UnAuthorized