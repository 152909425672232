import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
import React from "react";

const Apply = () => {
	const initilizeApplication = {};
	const validationSchema = {};

	const handleSubmit = (values) => {};
	return (
		<>
			<h1 className="px-4 font-bold">Become a Full-Stack developer. Less than 12 months.</h1>
			<section className="grid grid-cols-12 gap-4">
				<main className="col-span-9 p-3">
					<header className="mb-3">
						<h3 className="font-semibold py-2">Apply</h3>
						<dd>
							<dt className="font-medium">Requirements</dt>
							<dl>
								1. Must show the ability to attend all in-person
								sessions through the program.
							</dl>
							<dl>
								2. You must have a laptop to attend the in-person
								training sessions.
							</dl>
						</dd>
					</header>
					<Formik
						initialValues={initilizeApplication}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}>
						{({ errors, touched }) => (
							<Form>
                                <fieldset className="border-2 rounded-lg p-3 mb-4">
                                    <label for="instructions">
                                        <Field
                                            id="instructions"
                                            name="instructions"
                                            type="checkbox"
                                            value="yes"
                                            className="mr-1"
                                        />
                                        Have you read the instructions?
                                    </label>
                                </fieldset>
								<fieldset className="border-2 rounded-lg p-4 mb-4">
									<legend className="px-3 font-medium">
										Personal Information
									</legend>
									<div className="hstack my-2 gap-1">
										<Field
											className="mr-3 border-2 border-gray-400 text-gray-600 p-1 rounded-md"
											name="first_name"
											id="first_name"
											type="text"
											placeholder="First Name"
										/>
										<Field
											className="mr-3 border-2 border-gray-400 text-gray-600 p-1 rounded-md"
											name="last_name"
											id="last_name"
											type="text"
											placeholder="Last Name"
										/>
										<span>Gender</span> {" "}
										<label for="female">
											<Field
												type="radio"
												name="gender"
												id="female"
												value="Female"
											/>{" "}
											Female
										</label>{" "}
										<label for="male">
											<Field
												type="radio"
												name="gender"
												id="male"
												value="Male"
											/>{" "}
											Male
										</label>
									</div>
									<div>
										<Field
											className="border-2 border-gray-400 text-gray-600 rounded-md p-1"
											type="date"
											id="dob"
											name="dob"
											placeholder="Date of birth"
										/>
									</div>
								</fieldset>
								<fieldset className="border-2 rounded-lg p-4 mb-4">
									<legend className="px-3 font-medium">
										Contact Information
									</legend>
									<Field
										className="mr-3 border-2 border-gray-400 text-gray-600 p-1 rounded-md"
										name="phone"
										id="phone"
										type="text"
										placeholder="Phone number"
									/>
									<Field
										className="border-2 border-gray-400 text-gray-600 p-1 rounded-md"
										name="email"
										id="phone"
										type="text"
										placeholder="Email"
									/>
								</fieldset>
								<button
									type="submit"
									className="rounded-full bg-black text-white px-4 py-1">
									Submit
								</button>
							</Form>
						)}
					</Formik>
				</main>
				<sidebar  className="col-span-3 bg-gray-200 p-3 text-gray-600">
					<h2 className="font-bold">Help</h2>
					<ol>
						<li>Step</li>
						<li>Step</li>
						<li>Step</li>
						<li>Step</li>
						<li>Step</li>
						<li>Step</li>
						<li>Step</li>
						<li>Step</li>
					</ol>
				</sidebar>
			</section>
		</>
	);
};

export default Apply;
