function OrganisationDetailsSkeleton() {
  return (
    <header className="mb-5 flex gap-2">
      <div className="h-24 w-24 border rounded-md overflow-hidden animate-pulse bg-slate-200"></div>
      <div className="flex flex-col justify-between py-2 mx-2">
        <p className="font-semibold h-5 w-1/2 animate-pulse bg-slate-200"></p>
        <p className="font-semibold h-5 w-full animate-pulse bg-slate-200"></p>
        <div className="flex gap-5">
          <div className="flex items-center font-light gap-1 animate-pulse">
            <div className="rounded h-5 w-5 bg-slate-200"></div>
            <span className="text-sm font-light h-5 w-32 bg-slate-200 text-gray-500 animate-pulse"></span>
          </div>
          <div className="flex items-center font-light gap-1 animate-pulse">
            <div className="rounded h-5 w-5 bg-slate-200"></div>
            <span className="text-sm font-light h-5 w-32 bg-slate-200 text-gray-500 animate-pulse"></span>
          </div>
          <div className="flex items-center font-light gap-1 animate-pulse">
            <div className="rounded h-5 w-5 bg-slate-200"></div>
            <span className="text-sm font-light h-5 w-32 bg-slate-200 text-gray-500 animate-pulse"></span>
          </div>
          <div className="flex items-center font-light gap-1 animate-pulse">
            <div className="rounded h-5 w-5 bg-slate-200"></div>
            <span className="text-sm font-light h-5 w-32 bg-slate-200 text-gray-500 animate-pulse"></span>
          </div>
        </div>
      </div>
    </header>
  );
}

export default OrganisationDetailsSkeleton;
