import React from "react";
import ProgramNav from "../components/ProgramNav";
import { Outlet } from "react-router-dom";

function ProgramsLayout() {
  return (
    <div>
      <ProgramNav />
      <div className="flex mt-2 border h-4/5 border-red-500">
        <Outlet />
      </div>
    </div>
  );
}

export default ProgramsLayout;
