import React from "react";
import {
  Route,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Layout from "./components/shared/Layout";
import Outline from "./components/Outline";
import Home from "./components/Home";

import Login, {
  loader as loginLoader,
  action as loginAction,
} from "./features/auth/pages/Login";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import List from "./features/members/pages/List";
import PrivateRoute from "./components/helpers/PrivateRoute";
import SignOut from "./components/SignOut";
import UnAuthorized from "./components/UnAuthorized";
import Missing from "./components/Missing";
import ManageTasks from "./components/ManageTasks";
import Apply from "./components/Apply";

import Certify from "./components/certifications/Add";
import Verify from "./components/certifications/Verify";
import Certificate from "./components/certifications/Details";
import Certificates from "./components/certifications/List";

import Add, { loader as addOrganisationLoader } from "./features/organisations/pages/Add";
import Organisations, {
  loader as organisationsLoader,
} from "./features/organisations/pages/List";
import OrganisationLayout, {
  loader as OrganisationLayoutLoader,
} from "./features/organisations/layouts/OrganisationLayout";
import Overview, { loader as organisationLoader} from "./features/organisations/pages/Overview";

import AddProgram from "./features/organisations/pages/programs/Add";
import Programs from "./features/programs/pages/List";
import Program from "./features/programs/pages/Details";
import ProgramsLayout from "./features/programs/layouts/ProgramsLayout";
import Students from "./features/programs/pages/Students";
import Instructors from "./features/programs/pages/Instructors";
import Enrolments from "./features/programs/pages/Enrolments";
// import ProgramsLayout from "./components/programs/ProgramsLayout";
import Reviews from "./features/programs/pages/Reviews";
import { AuthLayout, authLayoutLoader } from "./features/auth/layouts";

const elements = createRoutesFromElements(
  <Route>
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route
        path="/login"
        element={<Login />}
        action={loginAction}
        loader={loginLoader}
      />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-out" element={<SignOut />} />
      <Route
        path="/second-cohort-fullstack-outline"
        element={<Outline id="fullstack-cohort-2" />}
      />
      <Route path="/apply" element={<Apply />} />
      <Route path="/unathorized" element={<UnAuthorized />} />
      <Route path="/certificates/:certificate/verify" element={<Verify />} />

      {/* <Route element={<PrivateRoute allowedRoles={["student", "admin"]} />}>
      <Route path="/dashboard" element={<Dashboard />} />
    </Route>
    <Route path="/members" element={<PrivateRoute allowedRoles={["admin"]} />}>
      <Route index element={<List />} />
      <Route path=":memberId/delete" element={<List />} />
      <Route path=":memberId/edit" element={<List />} />
    </Route>
    <Route
      element={
        <PrivateRoute
          allowedRoles={[
            "admin",
            "student",
            "intern",
            "apprentice",
            "employee",
          ]}
        />
      }
    >
      <Route path="/manage-tasks" element={<ManageTasks />} />
    </Route>
    <Route
      path="certificates"
      element={<PrivateRoute allowedRoles={["admin"]} />}
    >
      <Route index element={<Certificates />} />
      <Route path="new" element={<Certify />} />
      <Route path=":certificate" element={<Certificate />} />
      <Route path=":certificate/verify" element={<Verify />} />
      <Route path=":certificate/edit" element={<Certify />} />
    </Route>
    <Route
      path="organisations"
      element={
        <PrivateRoute allowedRoles={["member", "superadmin", "admin"]} />
      }
    >
      <Route index element={<Organisations />} />
      <Route path="new" element={<Add />} />
      <Route path=":organisation" element={<OrganisationLayout />}>
        <Route index element={<Overview />} />
        <Route
          path="programs"
          element={<PrivateRoute allowedRoles={["org-owner"]} />}
        >
          <Route index element={<Programs />} />
          <Route path="new" element={<AddProgram />} />
          <Route path=":program" element={<ProgramsLayout />}>
            <Route index element={<Program />} />
            <Route path="students" element={<Students />} />
            <Route path="instructors" element={<Instructors />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="enrollments" element={<Enrolments />} />
            <Route path="certificates" element={<Program />} />
          </Route>
        </Route>
      </Route>
    </Route> */}
      <Route path="*" element={<Missing />} />
    </Route>
    <Route path="/" element={<AuthLayout />} loader={authLayoutLoader}>
      <Route path="/dashboard" element={<Dashboard />} />
      {/* <Route
        path="/members"
        element={<PrivateRoute allowedRoles={["admin"]} />}
      > */}
      <Route path="/members" element={<List />} />
      <Route path="/members/:memberId/delete" element={<List />} />
      <Route path="/members/:memberId/edit" element={<List />} />
      <Route
        path="programs"
        element={<PrivateRoute allowedRoles={["org-owner"]} />}
      >
        <Route element={<ProgramsLayout />}>

        <Route index element={<Programs />} />
        <Route path="new" element={<AddProgram />} />
        <Route path=":program" element={<ProgramsLayout />}>
          <Route index element={<Program />} />
          <Route path="students" element={<Students />} />
          <Route path="instructors" element={<Instructors />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="enrollments" element={<Enrolments />} />
          <Route path="certificates" element={<Program />} />
        </Route>
        </Route>
      </Route>
      {/* </Route> */}
      {/* <Route
        element={
          <PrivateRoute
            allowedRoles={[
              "admin",
              "student",
              "intern",
              "apprentice",
              "employee",
            ]}
          />
        }
      > */}
      <Route path="/manage-tasks" element={<ManageTasks />} />
      {/* </Route> */}
      <Route path="/certificates" element={<Certificates />} />
      <Route path="/certificates/new" element={<Certify />} />
      <Route path="/certificates/:certificate" element={<Certificate />} />
      <Route path="/certificates/:certificate/verify" element={<Verify />} />
      <Route path="/certificates/:certificate/edit" element={<Certify />} />

      {/* <Route
        path="organisations"
        element={
          <PrivateRoute allowedRoles={["member", "superadmin", "admin"]} />
        }
      > */}
      <Route
        path="/organisations"
        element={<Organisations />}
        loader={organisationsLoader}
      />
      <Route path="/organisations/new" element={<Add />} loader={addOrganisationLoader}/>
      <Route
        path="/organisations/:organisation"
        element={<OrganisationLayout />}
        loader={OrganisationLayoutLoader}
      >
        <Route index element={<Overview />} loader={organisationLoader}/>
        <Route
          path="programs"
          element={<PrivateRoute allowedRoles={["org-owner"]} />}
        >
          <Route index element={<Programs />} />
          <Route path="new" element={<AddProgram />} />
          <Route path=":program" element={<ProgramsLayout />}>
            <Route index element={<Program />} />
            <Route path="students" element={<Students />} />
            <Route path="instructors" element={<Instructors />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="enrollments" element={<Enrolments />} />
            <Route path="certificates" element={<Program />} />
          </Route>
        </Route>
        <Route path="members" element={<List />} />
        <Route path="members/:memberId/delete" element={<List />} />
        <Route path="members/:memberId/edit" element={<List />} />
      </Route>
    </Route>
    {/* </Route> */}
  </Route>
);

const router = createBrowserRouter(elements);

function Routing() {
  return <RouterProvider router={router} />;
}

export default Routing;
