import React from "react";
import ReactPDF, {
	Page,
	Text,
	Image,
	View,
	Document,
	StyleSheet,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import { uniqueId, generateDate } from "../helpers/functions";
import LOGO from "../../assets/images/ablestate-logo.jpg";
import Signature from "../../assets/images/Signature.jpg";
import DotBG from "../../assets/images/dot-grid.png.jpeg";
// Create styles
const styles = StyleSheet.create({
	debug: {
		padding: 5,
		border: "1px solid red",
	},
	pageBackground: {
		position: "absolute",
		minWidth: "100%",
		minHeight: "100%",
		display: "block",
		height: "100%",
		width: "100%",
	},
	page: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		fontWeight: "light",
		color: "rgb(55, 65, 81)",
		borderWidth: 12,
		borderStyle: "solid",
		borderColor: "rgb(17, 24, 39)",
		backgroundImage: `url(${DotBG})`,
	},
	container: {
		display: "flex",
		width: "85%",
		flexDirection: "column",
		justifyContent: "space-between",
		fontSize: 18,
		minHeight: "100%",
	},
	logo: {
		width: 74,
		height: "auto",
		marginBottom: 10,
	},
	footer: {
		flexDirection: "row",
		width: "95%",
		justifyContent: "space-between",
	},
	marginBottom: {
		marginBottom: 5,
	},
	flex_sm: {
		flex: 1,
	},
	flex_md: {
		flex: 2,
	},
	textLeft: {
		textAlign: "Left",
	},
	textCenter: {
		textAlign: "center",
	},
	textRight: {
		textAlign: "right",
	},
	fs_large: {
		fontSize: 30,
	},
	fs_small: {
		fontSize: 8,
	},
	fw_bold: {
		fontWeight: "bold",
	},
	border_b_1: {
		borderBottomWidth: 1,
	},
	border_b_3: {
		borderBottomWidth: 3,
	},
	bsolid: {
		borderStyle: "solid",
	},
	borderGray: {
		borderColor: "rgb(17, 24, 39)",
	},
	borderOrange: {
		borderColor: "#ffa500",
	},
	orange: {
		color: "#ffa500",
	},
});

// Create Document Component
const PDF = ({ data }) => {
	const [qrURL, setQRURL] = React.useState("");
	const { beneficiary, certificate_id, desc, program } = data;
	React.useEffect(() => {
		console.log(data);
		setQRURL(
			myTextDataURL(
				`https://edu.theablestate.com/certificates/${certificate_id}/verify`
			)
		);
	}, []);

	const myTextDataURL = async (text) => {
		const URL = await QRCode.toDataURL(text, { errorCorrectionLevel: "H" });
		return URL;
	};
	return (
		<Document>
			<Page size="A4" orientation="landscape" style={[styles.page]}>
				<View style={[styles.container]}>
					<View style={[styles.flex_sm, { paddingTop: 50 }]}>
						<Image
							src={LOGO}
							style={[styles.logo, { marginHorizontal: "auto" }]}
						/>
					</View>
					<View style={[styles.flex_md, styles.textCenter]}>
						<Text
							style={[
								styles.title,
								styles.textCenter,
								styles.fs_large,
							]}>
							CERTIFICATE
						</Text>
						<Text style={[styles.sub_title, styles.textCenter]}>
							OF COMPLETION
						</Text>
						<hr
							style={[
								styles.border_b_3,
								styles.bsolid,
								styles.borderOrange,
								{
									width: "5%",
									marginHorizontal: "auto",
									marginTop: 10,
									marginBottom: 20,
								},
							]}
						/>
						<Text
							style={[
								styles.fw_bold,
								styles.textCenter,
								{ letterSpacing: "0.3", paddingVertical: 30 },
							]}>
							{beneficiary
								? beneficiary.split(":")[1]
								: "Student Name"}
						</Text>
						<Text style={[styles.textCenter]}>
							{desc ? desc : "successfully completed the"}
						</Text>
						<Text style={[styles.textCenter, styles.orange]}>
							{program && program} program
						</Text>
					</View>
					<View style={[styles.flex_sm, styles.footer]}>
						<View>
							<Text>{generateDate()}</Text>
							<hr
								style={[
									styles.border_b_1,
									styles.bsolid,
									styles.borderGray,
									{ width: "80%", marginVertical: 5 },
								]}
							/>
							<Text>Issuance Date</Text>
						</View>
						<View>
							{qrURL && (
								<Image
									src={qrURL}
									style={{ width: 80, height: "auto" }}
								/>
							)}
						</View>

						<View>
							<Image
								src={Signature}
								style={{
									width: 60,
									height: "auto",
									marginHorizontal: "auto",
								}}
							/>
							<hr
								style={[
									styles.border_b_1,
									styles.bsolid,
									styles.borderGray,
									{ width: "80%", marginVertical: 5 },
								]}
							/>
							<Text>Founder & CEO</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default PDF;
// ReactPDF.render(<PDF />, `downloads/example.pdf`);
