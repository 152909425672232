import { useState, useEffect } from "react";
import FormContext from "../../features/programs/context/FormContext";
import { v4 as uuid } from "uuid";
import { Appwrite } from "../../services";
const appwrite = new Appwrite();

function FormProvider({ children }) {
  const [programType, setProgramType] = useState("course");
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  useEffect(() => {
    appwrite.getAccount().then((user) => {
      setInitialValues((initialValues) => ({
        ...initialValues,
        course: {
          ...initialValues.course,
          creator: user.$id,
        },
      }));
    });
  }, []);
  const [initialValues, setInitialValues] = useState({
    code: uuid(),
    status: "pending",
    programType: "other",
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    thumbnail: "",
    thumbnailUrl: "",
    course: {
      price: "",
      currency: "",
      instructors: [],
      learningObjectives: [],
      requirements: [],
      prerequisites: [],
      organisations: [],
      creator: "",
      modules: [
        {
          title: "",
          description: "",
          lessons: [
            {
              name: "",
              sequenceNumber: "",
              description: "",
              content: "",
            },
          ],
        },
      ],
    },
  });

  const values = {
    programType,
    setProgramType,
    activeStepIndex,
    setActiveStepIndex,
    initialValues,
    setInitialValues,
  };

  return <FormContext.Provider value={values}>{children}</FormContext.Provider>;
}

export default FormProvider;
