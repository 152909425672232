import {
  Client as Appwrite,
  Account,
  Databases,
  ID,
  Teams,
  Storage,
  Functions,
  Role,
  Permission,
} from "appwrite";
import { Server } from "../appwrite/config";

let api = {
  sdk: null,

  provider: () => {
    if (api.sdk) {
      return api.sdk;
    }

    let appwrite = new Appwrite();
    appwrite.setEndpoint(Server.endpoint).setProject(Server.project);

    const account = new Account(appwrite);
    const database = new Databases(appwrite, Server.databaseID);
    const teams = new Teams(appwrite);
    const storage = new Storage(appwrite);
    const functions = new Functions(appwrite);

    api.sdk = { database, account, teams, storage, functions };
    return api.sdk;
  },

  createAccount: (values, provider) => {
    console.log("values: ", values);
    console.log("provider: ", values.provider);
    if (provider === "email") {
      const { email, password } = values;
      return api.provider().account.create("unique()", email, password, " ");
    }
  },

  getAccount: () => {
    let account = api.provider().account;
    return account.get();
  },

  getAccountPrefs: () => {
    let account = api.provider().account;
    return account.getPrefs();
  },

  listLogs: () => {
    let account = api.provider().account;
    return account.listLogs();
  },

  createSession: (email, password) => {
    return api.provider().account.createEmailSession(email, password);
  },

  getSession: () => {
    return api.provider().account.getSession("current");
  },

  updateName: (name) => {
    return api.provider().account.updateName(name);
  },

  updatePassword: (password) => {
    return api.provider().account.updatePassword(password);
  },

  updateEmail: (email) => {
    return api.provider().account.updateEmail(email);
  },

  deleteCurrentSession: () => {
    return api.provider().account.deleteSession("current");
  },
  /**
   *
   * @param {String} databaseId
   * @param {String} collectionId
   * @param {Object} data
   * @param {String} Id
   * @param {Object} permissions
   * @returns
   */
  createDocument: (
    databaseId,
    collectionId,
    data,
    id = ID.unique(),
    permissions = []
  ) => {

    console.log("Permissions: ", permissions)
    return api.provider().database.createDocument(
      databaseId,
      collectionId,
      id,
      data,
      permissions
    );
  },

  listDocuments: (databaseId, collectionId) => {
    return api.provider().database.listDocuments(databaseId, collectionId);
  },

  getDocument: (databaseId, collectionId, documentId) => {
    return api
      .provider()
      .database.getDocument(databaseId, collectionId, documentId);
  },

  updateDocument: (databaseId, collectionId, documentId, data) => {
    return api
      .provider()
      .database.updateDocument(databaseId, collectionId, documentId, data);
  },

  deleteDocument: (databaseId, collectionId, documentId) => {
    return api
      .provider()
      .database.deleteDocument(databaseId, collectionId, documentId);
  },

  listMemberships: () => {
    const memberships = api
      .provider()
      .teams.listMemberships(Server.systemTeamID);
    return memberships;
  },

  createTeam: (teamName) => {
    return api.provider().teams.create("unique()", teamName);
  },

  listTeams: () => {
    return api.provider().teams.list();
  },

  createFile: (file, bucketID) => {
    return api.provider().storage.createFile(bucketID, "unique()", file);
  },

  createOrganisationLogo: (file) => {
    return api
      .provider()
      .storage.createFile(Server.logosBucketID, "unique()", file, [
        Permission.read(Role.any()),
        Permission.update(Role.team(Server.systemTeamID)),
        Permission.delete(Role.team(Server.systemTeamID)),
      ]);
  },

  createProgramResource: (file) => {
    return api
      .provider()
      .storage.createFile(Server.resourcesBucketID, "unique()", file, [
        Permission.read(Role.any()),
        Permission.update(Role.team(Server.systemTeamID)),
        Permission.delete(Role.team(Server.systemTeamID))
      ]);
  },  

  getFileForView: (fileID, bucketID) => {
    return api.provider().storage.getFileView(bucketID, fileID);
  },

  createFunctionExecution: (functionID, data, async = true) => {
    return api.provider().functions.createExecution(functionID, data, async);
  },

  getExecution: async (functionID, executionID) => {
    return await api.provider().functions.getExecution(functionID, executionID);
  },
};

export default api;
