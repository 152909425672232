import React from "react";
import { getInitials } from "../helpers/getInitials";
import { BsDot } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function OrganisationCard({ organisation }) {
  const navigate = useNavigate();

  return (
    <div
      className="py-5 px-5 rounded-lg border border-1 flex gap-2 cursor-pointer w-96 justify-between h-fit"
      onClick={() => {
        navigate(`/organisations/${organisation.$id}`);
      }}
    >
      <div className="flex flex-wrap gap-2">
        <div className="w-12 h-12 rounded-full overflow-hidden flex justify-center items-center">
          {organisation?.logoUrl ? (
            <img
              className="h-full w-full"
              src={organisation?.logoUrl}
              alt={organisation?.name && getInitials(organisation?.name)}
            />
          ) : (
            <div className="w-12 h-12 flex justify-center items-center rounded-full bg-orange-500 uppercase text-2xl text-white">
              {organisation?.name && getInitials(organisation?.name)}
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center">
          <div className="text-[#1F2328]">{organisation?.name}</div>
          <div className="flex text-sm font-light">
            <BsDot
              color={"#589680"}
              size={22}
              className="border-[0.9px] rounded mr-2 bg-green-50 border-[#FAFAFA] text-[#4ceeb5]"
            />
            {"   "}
            <div className="text-[#1F2328] text-xs">{organisation?.status}</div>
          </div>
        </div>
      </div>
      <div className="flex items-end cursor-pointer">
        <button className="bg-gray-100 font-semibold text-red-700 border py-1 px-2 rounded-md cursor pointer text-sm hover:bg-red-700 hover:text-white duration-75 ease-in hover:border-red-700" type="button" onClick={(event) => {
            event.stopPropagation()
        }}>
          leave
        </button>
      </div>
    </div>
  );
}

export default OrganisationCard;
