import { Suspense, Fragment } from "react";
import MemberProfiles from "../../../components/ui/Members";
import { defer, Await, useLoaderData } from "react-router-dom";
import { Appwrite } from "../../../services";
import {
  DATABASE_ID,
  ORGANISATIONS_COLLECTION_ID,
} from "../../../data/constants";
const appwrite = new Appwrite();

function OverviewSkeleton() {
  return (
    <div className="my-2 flex flex-col gap-y-1 ">
      <div className="flex items-center py-2 bg-slate-200 w-32 rounded h-5"></div>
      <div className="ml-3 bg-slate-200 animate-pulse h-20 w-32 rounded"></div>
      <div className="w-64 h-5 bg-slate-200 animate-pulse rounded"></div>
      <div className="ml-3 bg-slate-200 animate-pulse text-transparen w-64 h-5 rounded"></div>
    </div>
  );
}

function Overview() {
  const data = useLoaderData();

  return (
    <div className="my-2">
      <Suspense fallback={<OverviewSkeleton />}>
        <Await resolve={data.organisation}>
          {({ members, partners }) => {
            return (
              <Fragment>
                <span className="text-sm font-semibold text-gray-600">
                  Members
                </span>
                <span className="mx-1 text-sm text-gray-500 font-ligh">|</span>
                <span className="text-sm text-gray-500 font-light">
                  total {members?.length ?? 0}
                </span>
                <span className="px-2 text-sm font-light text-blue-600 hover:underline cursor-pointer">
                  view all
                </span>
                {members?.length > 0 ? (
                  <MemberProfiles profiles={members} />
                ) : (
                  <div className="text-sm font-extralight pl-3">
                    No Members{" "}
                  </div>
                )}
                <div>
                  <div>
                    <span className="text-sm font-semibold text-gray-600">
                      Partners
                    </span>
                    <span className="mx-1 text-sm text-gray-500 font-ligh">
                      |
                    </span>
                    <span className="text-sm text-gray-500 font-light">
                      total {partners?.length ?? 0}
                    </span>
                  </div>
                  {partners?.length > 0 ? (
                    <div>partners here</div>
                  ) : (
                    <div className="text-sm font-extralight pl-3">
                      No partnerships established yet
                    </div>
                  )}
                </div>
              </Fragment>
            );
          }}
        </Await>
      </Suspense>
    </div>
  );
}

export const loader = async ({ params }) => {
  const organisation = params.organisation;
  const organisationPromise = appwrite.getDocument(
    DATABASE_ID,
    ORGANISATIONS_COLLECTION_ID,
    organisation
  );
  return defer({
    organisation: organisationPromise,
  });
};

export default Overview;
