import { useEffect, useState, Suspense, Fragment } from "react";
import api from "../../../components/helpers/appwrite/api";
import { Server } from "../../../components/helpers/appwrite/config";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import OrganisationCard from "../../../components/ui/OrganisationCard";
import { useAuth } from "../../../components/hooks/useAuth";
import { Query } from "appwrite";
import { OrganizationIcon } from "@primer/octicons-react";
import {
  useNavigate,
  NavLink,
  useLoaderData,
  defer,
  Await,
} from "react-router-dom";
import { Appwrite } from "../../../services";
import {
  SYSTEM_TEAM_ID,
  DATABASE_ID,
  ORGANISATIONS_COLLECTION_ID,
} from "../../../data/constants";

const appwrite = new Appwrite();

export default function List() {
  const location = useLocation();
  const navigate = useNavigate();
  const [organisations, setOrganisations] = useState([]);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const loaderData = useLoaderData();
  const { databaseID, organisationCollectionID, systemTeamID } = Server;

  const handleMouseEnter = (link) => {
    setActiveLink(link);
  };

  const handleMouseLeave = () => {
    setActiveLink(location.pathname);
  };

  // useEffect(() => {
  //   if (userTeamMemberships) {
  //     fetchOrganisations();
  //   }
  //   return () => {};
  // }, [userTeamMemberships, user]);

  // const fetchOrganisations = async () => {
  //   const query = userTeamMemberships[`${systemTeamID}`].roles.includes(
  //     "superadmin"
  //   )
  //     ? []
  //     : [Query.equal("creator", user.$id)];

  //   const { documents: organisations } = await api
  //     .provider()
  //     .database.listDocuments(databaseID, organisationCollectionID, query);

  //   console.log("Organisations", organisations);
  //   setOrganisations(() => organisations);
  // };

  return (
    <div className="wrapper">
      <Helmet>
        <title>Organisations</title>
      </Helmet>
      <div className="content">
        <div className="flex h-[1.85rem]">
          <NavLink
            to="/organisations"
            className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
              activeLink === "/organisations"
                ? "text-orange-500 border-b-2 border-orange-400"
                : "text-gray-800"
            }`}
            onMouseEnter={() => handleMouseEnter("/organisations")}
            onMouseLeave={handleMouseLeave}
          >
            All
          </NavLink>
          <NavLink
            to={`/organisations/new`}
            className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
              activeLink === `/organisations/new`
                ? "text-orange-500 border-b-2 border-orange-400"
                : "text-gray-800"
            }`}
            onMouseEnter={() => handleMouseEnter(`/organisations/new`)}
            onMouseLeave={handleMouseLeave}
          >
            Add New
          </NavLink>{" "}
        </div>
      </div>
      <Suspense fallback={<div>loading</div>}>
        <Await resolve={loaderData?.organisations}>
          {({ total, documents: organisations }) => (
            <Fragment>
              <h2 className="my-2 flex items-center">
                <span className="text-xl font-semibold">Organisations</span>
                <span className="font-light text-[#A9A9A9] px-2">|</span>
                <span className="text-sm text-[#A9A9A9]">{total} total</span>
              </h2>
              <div className="w-full flex flex-wrap gap-5">
                {total > 0 &&
                  organisations.map((organisation, index) => (
                    <OrganisationCard organisation={organisation} key={index} />
                  ))}
              </div>
              {!total > 0 && (
                <div
                  className="w-fit text-center cursor-pointer absolute inset-x-1/2 inset-y-1/2 group"
                  onClick={() => {
                    navigate("/organisations/new");
                  }}
                >
                  <OrganizationIcon
                    size="medium"
                    className="text-gray-700 group-hover:text-orange-400"
                  />
                  <div className="text-gray-700 text-sm group-hover:text-orange-400">
                    Add organisation
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </Await>
      </Suspense>
    </div>
  );
}

export const loader = () => {
  const organisationsPromise = api
    .provider()
    .database.listDocuments(DATABASE_ID, ORGANISATIONS_COLLECTION_ID);

  return defer({
    organisations: organisationsPromise,
  });
};
