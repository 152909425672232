import { createContext, useContext } from "react";
import { Appwrite } from "../services";

const AppwriteContext = createContext();

function AppwriteProvider({ children }) {
  const appwrite = new Appwrite();

  const logout = async () => {
    return appwrite.deleteCurrentSession();
  };

  const values = {
    appwrite,
    logout,
  };

  return (
    <AppwriteContext.Provider value={values}>
      {children}
    </AppwriteContext.Provider>
  );
}

function useAppwrite() {
  return useContext(AppwriteContext);
}

export { AppwriteProvider, useAppwrite };
