import React from "react";
import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import useForm from "../../../../hooks/useForm";
import TextInputField from "../../../../../../components/form/TextInputField";
import TextAreaField from "../../../../../../components/form/TextAreaField";

function Step3() {
  const { initialValues, setInitialValues, setActiveStepIndex } = useForm();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        setInitialValues({ ...initialValues, ...values });
        setActiveStepIndex(2);
      }}
    >
      {({ values }) => (
        <Form className="">
          <FieldArray name="course.modules">
            {({ pop, push }) => (
              <div className="">
                {values?.course?.modules?.length > 0 &&
                  values?.course?.modules?.map((module, index) => (
                    <div key={index}>
                      <TextInputField
                        label="Title"
                        name={`course.modules.${index}.title`}
                        id={`course.modules.${index}.title`}
                        mandatory={true}
                      />
                      <TextAreaField
                        label="Description"
                        name={`course.modules.${index}.description`}
                        id={`course.modules.${index}.description`}
                        mandatory={true}
                      />
                      <TextInputField
                        label="module"
                        name={`course.modules.${index}.title`}
                        id={`course.modules.${index}.title`}
                        mandatory={true}
                      />
                    </div>
                  ))}
                <div className="flex gap-2 justify-end">
                  <button
                    className="py-1 px-1.5 bg-green-500 text-white rounded text-sm"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      console.log("Values: ", values);
                      push({
                        title: "",
                        description: "",
                        lessons: [
                          {
                            name: "",
                            sequenceNumber: "",
                            description: "",
                            content: "",
                          },
                        ],
                      });
                    }}
                  >
                    Add
                  </button>
                  <button
                    className="py-1 px-1.5 bg-red-500 text-white rounded text-sm"
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      if (values?.course?.modules?.length > 1) pop();
                    }}
                  >
                    Remove
                  </button>
                </div>
                <div className="mt-2 flex justify-between sticky">
                  <button
                    type="button"
                    className="text-white text-sm font-semi bg-orange-400 capitalize hover:bg-orange-500 transition-all duration 200 text-md px-1.5 py-1 rounded-sm ease-in"
                    onClick={() => setActiveStepIndex(0)}
                  >
                    Previous
                  </button>
                  <button
                    type="submit"
                    className="text-white text-sm font-semi bg-orange-400 capitalize hover:bg-orange-500 transition-all duration 200 text-md px-1.5 py-1 rounded-sm ease-in"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
}

export default Step3;
