import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

function OrganisationNav({ organisation }) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const handleMouseEnter = (link) => {
    setActiveLink(link);
  };

  const handleMouseLeave = () => {
    setActiveLink(location.pathname);
  };

  return (
    <div className="flex h-[1.85rem]">
      <div className="w-full h-[1.85rem]">
        <NavLink
          to="/organisations"
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink === "/organisations"
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() => handleMouseEnter("/organisations")}
          onMouseLeave={handleMouseLeave}
        >
          All
        </NavLink>
        <NavLink
          to={`/organisations/${organisation}`}
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink === `/organisations/${organisation}`
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() =>
            handleMouseEnter(`/organisations/${organisation}`)
          }
          onMouseLeave={handleMouseLeave}
        >
          Overview
        </NavLink>
        <NavLink
          to={`/organisations/${organisation}/programs`}
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink.startsWith(`/organisations/${organisation}/programs`)
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() =>
            handleMouseEnter(`/organisations/${organisation}/programs`)
          }
          onMouseLeave={handleMouseLeave}
        >
          Programs
        </NavLink>
        <NavLink
          to={`/organisations/${organisation}/members`}
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink.startsWith(`/organisations/${organisation}/members`)
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() =>
            handleMouseEnter(`/organisations/${organisation}/members`)
          }
          onMouseLeave={handleMouseLeave}
        >
          Members
        </NavLink>
        <NavLink
          to={`/organisations/${organisation}/partners`}
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink.startsWith(`/organisations/${organisation}/partners`)
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() =>
            handleMouseEnter(`/organisations/${organisation}/partners`)
          }
          onMouseLeave={handleMouseLeave}
        >
          Partners
        </NavLink>
      </div>
      {/* <NavLink
        to={`/organisations/${organisation}/manage`}
        className="py-1 px-3 font-bold text-sm cursor-pointer border rounded-md border-black hover:text-white hover:bg-orange-500 hover:border-orange-500 duration-75 ease-in"
      >
        manage
      </NavLink> */}
    </div>
  );
}

export default OrganisationNav;
