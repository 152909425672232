import { useState, useEffect } from "react";
import { toast } from "react-toastify";

function ImageUpload({ inputStyles, urlRef, setFieldValue, fileRef }) {
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setFieldValue(urlRef, objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, urlRef, setFieldValue]);

  const onFileSelect = (event) => {
    if (!event.target.files[0] || event.target.files.length === 0) {
      setSelectedFile(undefined);
    } else {
      if (event.target.files[0].size > 1000000) {
        toast.error("Image is to big", { position: "top-right" });
        return;
      }

      setSelectedFile(event.target.files[0]);
      setFieldValue(fileRef, event.currentTarget.files[0]);
    }
  };

  return (
    <input
      type="file"
      accept=".png, .jpg, .jpeg"
      name={urlRef}
      id={urlRef}
      className={inputStyles}
      onChange={onFileSelect}
    />
  );
}

export default ImageUpload;
