import useForm from "../../../hooks/useForm";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import { useEffect } from "react";

function Step() {
  const { activeStepIndex } = useForm();
  useEffect(() => {}, [activeStepIndex]);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Step1 />;
      break;
    case 1:
      stepContent = <Step2 />;
      break;
    case 2:
      stepContent = <Step3 />;
      break;
    case 3:
      stepContent = <Step4 />;
      break;
    default:
      break;
  }

  return stepContent;
}

export default Step;
