import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppwrite } from "../../context/Appwrite";

function PrivateLink({ allowedRoles, children, ...rest }) {
  const { appwrite } = useAppwrite();
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    appwrite
      .getAccount()
      .then(async (user) => {
        const { memberships } = await appwrite.listTeamMemberships();
        const roles = memberships.find(
          (membership) => membership.userId === user.$id
        ).roles;
        setRoles(() => roles);
      })
      
  }, [ appwrite ]);

  return (
    roles.find((role) => allowedRoles.includes(role)) && (
      <Link {...rest}>{children}</Link>
    )
  );
}

export default PrivateLink;
