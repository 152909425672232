import React from "react";
import { useField } from "formik";
import { IoIosInformationCircleOutline } from "react-icons/io";

function TextInputField({ label, mandatory, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className="w-96 py-2">
      <label className=" w-full text-sm text-black font-semibold">
        {label}
        {mandatory && (
          <span className="text-sm font-semibold mx-1 text-red-700">*</span>
        )}
      </label>
      <div className="relative flex items-center">
        <input
          name="name"
          className={`w-full border py-1 rounded-md  text-sm focus:outline-none font-light px-2 ${
            meta?.error && meta?.touched
              ? "border-1 border-red-700 bg-red-100"
              : "border-slate-200 bg-gray-100 focus:bg-white"
          }`}
          {...field}
          {...props}
        />
        {/* <div
          className={`absolute right-0 h-fit w-fit pr-1 ${
            meta?.touched && meta?.error ? "visible" : "hidden"
          }`}
        >
          <IoIosInformationCircleOutline size={22} className="text-red-700" />
        </div> */}
      </div>
      {meta?.touched && meta?.error && (
        <div className="text-xs font-light text-red-700">{meta?.error}</div>
      )}
    </div>
  );
}

export default TextInputField;
