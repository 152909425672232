import { Formik, Field, ErrorMessage, Form } from "formik";
import { useSubmit } from "react-router-dom";
import { loginSchema } from "../utils/validator";
import { Appwrite } from "../../../services";
import { redirect, json, useActionData } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const appwrite = new Appwrite();

export default function Login() {
  const submit = useSubmit();
  const loaderData = useActionData();
  const initialValues = {
    email: "",
    password: "",
  };

  loaderData?.error && toast.error(loaderData.error, { toastId: "loginError" });
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <ToastContainer />
      <div className="bg-white border rounded-lg px-8 pt-6 pb-8 mb-4 dark:bg-gray-900 dark:border-gray-800">
        <h1 className="text-center font-semibold text-2xl mb-4">Login</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            submit(values, {
              action: "/login",
              method: "post",
            });
            setSubmitting(false);
            resetForm({ values: initialValues });
          }}
        >
          {() => (
            <Form>
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  E-mail
                </label>
                <Field
                  className="p-2 appearance-none leading-tight outline-0 bg-gray-100 border border-gray-300 w-full rounded-lg focus:border-orange-400 focus:bg-white focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:focus:bg-gray-600 dark:focus:border-gray-500"
                  id="email"
                  name="email"
                  type="text"
                  placeholder="email"
                />
                <ErrorMessage name="email">
                  {(message) => (
                    <p className="text-red-500 text-xs italic">{message}</p>
                  )}
                </ErrorMessage>
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <Field
                  className="p-2 appearance-none leading-tight outline-0 bg-gray-100 border border-gray-300 w-full rounded-lg focus:border-orange-400 focus:bg-white focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:focus:bg-gray-600 dark:focus:border-gray-500"
                  id="password"
                  name="password"
                  type="password"
                  placeholder="******************"
                />
                <ErrorMessage name="password">
                  {(message) => (
                    <p className="text-red-500 text-xs italic">{message}</p>
                  )}
                </ErrorMessage>
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="px-4 py-1 transition bg-white hover:-translate-y-1 hover:bg-orange-100 duration-300 mx-auto max-w-md rounded-full border border-orange-500 dark:border-gray-700 dark:text-gray-500"
                  type="submit"
                >
                  Sign In
                </button>
                <a
                  className="inline-block align-baseline font-semibold text-sm text-orange-500 hover:text-orange-600"
                  href="#"
                >
                  Forgot Password?
                </a>
              </div>
              <div>
                <span className="text-sm px-2">Don't have an account?</span>
                <a
                  className="inline-block align-baseline font-semibold text-sm text-orange-500 hover:text-orange-600 cursor-pointer"
                  href="/sign-up"
                >
                  SignUp
                </a>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export const loader = async ({ request }) => {
  // check if there is a user that is currently logged in to the system.
  // If there is, redirect to the path where the user came from or to the dashboard.
  // If there is no user logged in, render the Login component.
  const pathname =
    new URL(request.url).searchParams.get("redirectTo") || "/dashboard";
  try {
    await appwrite.getAccount();
    return redirect(pathname);
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const action = async ({ request }) => {
  // call the login method of the appwrite api
  // if the login was successful, redirect to the path where the user came from or to the dashboard.
  // if the login was unsuccessful, display an error message.
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");
  const pathname =
    new URL(request.url).searchParams.get("redirectTo") || "/dashboard";

  try {
    await appwrite.createSession(email, password);
    return redirect(pathname);
  } catch (error) {
    return json({ error: error.message }, { status: error.code });
  }
};
