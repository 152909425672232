import api from "./appwrite/api";

const signOut = async (setUser) => {
	await api.deleteCurrentSession()
	setUser(null)
};

const uniqueId = () => {
	const dateString = Date.now().toString(36);
	const randomness = Math.random().toString(36).substr(2);
	return dateString + randomness;
};

const generateDate = () => {
	const date = new Date();
	return date.toLocaleDateString("en-US");
};

export { signOut, uniqueId, generateDate };
