import React, { useState } from "react";
import { getInitials } from "../helpers/getInitials";

const MemberProfiles = ({ profiles, offset = 6 }) => {
  let visibleMembers = profiles.slice(0, offset);
  console.log("visibleMembers: ", visibleMembers);
  let colors = ["#FFA500", "#00FF00", "#FFFF00", "#B76E79", "#E6E6FA"];

  const [hoverIndex, setHoverIndex] = useState(-1);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(-1);
  };

  if (hoverIndex) console.log("hover index: ", hoverIndex);

  return (
    <div className="flex overflow-x-auto">
      {visibleMembers.map((profile, index) => {
        const { name } = profile;

        // Apply overlapping effect for the first five elements
        const profileStyles =
          index < 5
            ? {
                marginLeft: `-${2 * index}px`,
                zIndex: `${10 * (index + 1)}`,
                backgroundColor: colors[index] ?? "#00FF00",
              }
            : {};

        return (
          <div
            key={index}
            // className={`w-12 h-12 rounded-full m-2`}
            className={`h-14 w-14 rounded-full -mr-4 border-[3px] border-white flex justify-center items-center cursor-pointer bg-gray-100 text-xs overflow-hidden relative`}
            style={profileStyles}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            {hoverIndex > 4 && hoverIndex === index ? (
              <span className=" text-xs absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-light  text-blue-500 underline ml-1 cursor-pointer">
                view all
              </span>
            ) : (
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg  text-black cursor-pointer">
                {getInitials(name ?? "Member")}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MemberProfiles;
