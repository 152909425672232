import { Link } from "react-router-dom";

export default function List() {
	return (
		<div className="wrapper">
			<div className="content">
				<header className="py-4">
					<Link
						to="/certificates/new"
						className="bg-orange-600 text-white rounded-full px-4 py-2">
						Issue Certificate
					</Link>
				</header>
				<h1>Certificates</h1>
				{/* 				<ul>
					<li>
						<Link to="/certificates/8909">8909</Link> |{" "}
						<Link to="/certificates/8909/edit">Edit</Link>
					</li>
					<li>
						<Link to="/certificates/8901">8901</Link> |{" "}
						<Link to="/certificates/8909/edit">Edit</Link>
					</li>
				</ul> */}
			</div>
		</div>
	);
}
