import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import api from "./helpers/appwrite/api";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { useAuth } from "../components/hooks/useAuth";
import { uuid } from "uuidv4";
import { Server } from "./helpers/appwrite/config";
import { Query } from "appwrite";

function SignUp() {
  const { user, setSession } = useAuth();
  const [errorMsg, setErrorMsg] = React.useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/dashboard";

  const loginSchema = Yup.object().shape({
    email: Yup.string().required("email is required!"),
    password: Yup.string().required("Password is required!"),
  });

  return user ? (
    <Navigate to={from} replace />
  ) : (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="bg-white border rounded-lg px-8 pt-6 pb-8 mb-4 dark:bg-gray-900 dark:border-gray-800">
        <h1 className="text-center font-semibold text-2xl mb-4">SignUp</h1>
        {errorMsg && <p className="text-red-500">{errorMsg}</p>}
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={loginSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              const { email, password } = values;
              const user = await api.createAccount(values, "email");

              if (user) {
                const session = await api.createSession(email, password);
                console.log("New login session: ", session)
                setSession(() => session);
                navigate(from, { replace: true });
              }
            } catch (error) {
              setErrorMsg(error.message);
            } finally {
              setSubmitting(false);
              resetForm({ values: { email: "", password: "" } });
            }
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  E-mail
                </label>
                <Field
                  className="p-2 appearance-none leading-tight outline-0 bg-gray-100 border border-gray-300 w-full rounded-lg focus:border-orange-400 focus:bg-white focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:focus:bg-gray-600 dark:focus:border-gray-500"
                  id="email"
                  name="email"
                  type="text"
                  placeholder="email"
                />
                {errors.email && touched.email && (
                  <p className="text-red-500 text-xs italic">{errors.email}</p>
                )}
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 dark:text-gray-400 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <Field
                  className="p-2 appearance-none leading-tight outline-0 bg-gray-100 border border-gray-300 w-full rounded-lg focus:border-orange-400 focus:bg-white focus:outline-none focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:focus:bg-gray-600 dark:focus:border-gray-500"
                  id="password"
                  name="password"
                  type="password"
                  placeholder="******************"
                />
                {errors.password && touched.password && (
                  <p className="text-red-500 text-xs italic">
                    {errors.password}
                  </p>
                )}
              </div>

              <button
                className="px-4 py-1 transition bg-white hover:-translate-y-1 hover:bg-orange-100 duration-300 mx-auto max-w-md rounded-full border border-orange-500 dark:border-gray-700 dark:text-gray-500"
                type="submit"
              >
                Sign Up
              </button>
              <div>
                <span className="text-sm px-2">Already have an account?</span>
                <a
                  className="inline-block align-baseline font-semibold text-sm text-orange-500 hover:text-orange-600 cursor-pointer"
                  href="/login"
                >
                  Login
                </a>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default SignUp;
