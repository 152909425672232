import * as React from "react";
// import supabase from "../helpers/supabase";
import { Link } from "react-router-dom";
import PDF from "./PDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { QRCodeSVG } from "qrcode.react";
import { uniqueId, generateDate } from "../helpers/functions";
import Logo from "../../assets/images/ablestate-logo.jpg";
import Signature from "../../assets/images/Signature.jpg";
import DotGrid from "../../assets/images/dot-grid.png.jpeg";
import YSSW from "../../assets/images/y-so-serious-white.png";
export default function Add() {
	document.title = "Ablestate - Add Certificate";

	const certificateId = uniqueId();
	const [students, setStudents] = React.useState([]);
	const [certificate, setCertificate] = React.useState({
		orgname: "",
		beneficiary: "",
		program: "",
		desc: "",
		official_partner_logo: true,
		issure_sign_status: true,
		beneficiary_code: true,
		program_code: true,
		certification_code: true,
		layout: "default",
		certificate_id: certificateId,
	});

	let {
		orgname,
		desc,
		beneficiary,
		program,
		official_partner_logo,
		issure_sign_status,
		beneficiary_code,
		program_code,
		certification_code,
	} = certificate;
	React.useEffect(async () => {
		// const { data, error } = await supabase.from("profiles").select();
		// setStudents(data);
		// // console.log(data);
		// console.log(supabase.auth.user());
	}, []);
	const handleSubmit = async (e) => {
		// const { id: creator } = supabase.auth.user();
		e.preventDefault();
		// try {
		// 	const { data, error } = await supabase.from("certificates").insert([
		// 		{
		// 			student_id: certificate?.beneficiary?.split(":")[0],
		// 			student_name: certificate?.beneficiary?.split(":")[1],
		// 			code: certificateId,
		// 			creator_id: creator,
		// 		},
		// 	]);
		// } catch (e) {
		// 	// throw Error;
		// 	console.log(e);
		// }
		// console.log(certificate);
	};

	return (
		<main className="grid grid-cols-12 pb-5">
			<header className="col-span-12 py-4">
				<h1>
					Generate Certificate{" "}
					<Link
						to="/certificates"
						className="bg-gray-900 text-white  rounded-full px-4 py-1">
						Back
					</Link>
				</h1>
			</header>

			<aside className="col-span-3 border border-gray-400">
				<h2 className="p-3 border-b border-gray-400">
					Certificate Details
				</h2>
				<form onSubmit={handleSubmit} className="p-3">
					<div className="py-4">
						<label className="text-gray-500" htmlFor="organisation">
							Organisation
						</label>
						<select
							id="organisation"
							className="w-full border-2 border-gray-300 p-2 text-gray-500"
							value={orgname}
							onChange={(e) =>
								setCertificate({
									...certificate,
									orgname: e.target.value,
								})
							}>
							<option value="None">- Select -</option>
							<option value="Ablestate Creatives Ltd">
								Ablestate Creatives Ltd
							</option>
						</select>
					</div>
					<div className="py-2">
						<label className="text-gray-500">Program</label>
						<select
							className="w-full border-2 border-gray-300 p-2 text-gray-500"
							value={program}
							onChange={(e) =>
								setCertificate({
									...certificate,
									program: e.target.value,
								})
							}>
							<option value="None">- Select -</option>
							<option value="Computer Operator">
								Computer Operator
							</option>
							<option value="Full-stack Web Development">
								Full-stack Web Development
							</option>
						</select>
					</div>

					<div className="py-2">
						<label className="text-gray-500">Receipient</label>
						<select
							className="w-full border-2 border-gray-300 p-2 text-gray-500"
							value={beneficiary}
							onChange={(e) =>
								setCertificate({
									...certificate,
									beneficiary: e.target.value,
								})
							}>
							<option value="None">- Select -</option>
							{students.map((student) => {
								const { user_meta } = student;
								return (
									<option
										key={student.id}
										value={`${student.id}:${user_meta?.name?.first_name} ${user_meta?.name?.last_name}`}>
										{user_meta?.name?.first_name}{" "}
										{user_meta?.name?.last_name}
									</option>
								);
							})}
						</select>
					</div>

					<div className="py-2">
						<label className="text-gray-500">Description</label>
						<textarea
							className="w-full border-2 border-gray-300 p-2 text-gray-500"
							value={desc}
							onChange={(e) =>
								setCertificate({
									...certificate,
									desc: e.target.value,
								})
							}
							placeholder="Description..."></textarea>
					</div>
					{/*
					<div>
						<label>Settings</label>
						<input
							type="button"
							onClick={() =>
								setCertificate({
									...certificate,
									official_partner_logo:
										!official_partner_logo,
								})
							}
							value="Include official partner logo"
						/>
						<input
							type="button"
							onClick={() =>
								setCertificate({
									...certificate,
									issure_sign_status: !issure_sign_status,
								})
							}
							value="Include issuer signature"
						/>
						<input
							type="button"
							onClick={() =>
								setCertificate({
									...certificate,
									beneficiary_code: !beneficiary_code,
								})
							}
							value="Include beneficiary code"
						/>
						<input
							type="button"
							onClick={() =>
								setCertificate({
									...certificate,
									program_code: !program_code,
								})
							}
							value="Include program code"
						/>
						<input
							type="button"
							onClick={() =>
								setCertificate({
									...certificate,
									certification_code: !certification_code,
								})
							}
							value="Include certification code"
						/>
					</div>

					<div>
						<label>Layout</label>
						<input
							type="button"
							onClick={() =>
								setCertificate({
									...certificate,
									layout: "default",
								})
							}
							value="Default"
						/>
						<input
							type="button"
							onClick={() =>
								setCertificate({ ...certificate, layout: 2 })
							}
							value="Layout 2"
						/>
						<input
							type="button"
							onClick={() =>
								setCertificate({ ...certificate, layout: 3 })
							}
							value="Layout 3"
						/>
						<input
							type="button"
							onClick={() =>
								setCertificate({ ...certificate, layout: 4 })
							}
							value="Layout 4"
						/>
					</div>
 */}
					<div className="py-2 flex justify-center">
						<button
							type="submit"
							value="Save"
							className="rounded-full px-5 py-1 bg-orange-500 text-white transition hover:bg-gray-900 hover:transition-all">
							Save Certificate
						</button>
						<PDFDownloadLink
							className="btn btn-primary"
							document={<PDF data={certificate} />}
							fileName={`#${certificate.beneficiary}.pdf`}>
							{({ loading }) =>
								loading ? "Loading document" : "↓ Download PDF"
							}
						</PDFDownloadLink>
					</div>
				</form>
			</aside>
			<article className="col-span-9 pl-8">
				{!certificate.beneficiary && (
					<>
						<h2 className="uppercase">Certificate Preview</h2>
						<p className="text-gray-400">
							View impression of the certificate here!
						</p>
					</>
				)}
				{/* <p>
					<Link to={`/certificates/${certificateId}/verify`}>
						Verify
					</Link>
				</p> */}
				<section
					className="border-8 border-gray-900 w-full h-full p-5 bg-white flex flex-col gap-4 justify-center items-center text-gray-600"
					style={{ backgroundImage: `url(${DotGrid})` }}>
					<img src={Logo} className="w-16" />
					<header className="text-center">
						<h3 className="text-gray-700 text-4xl">CERTIFICATE</h3>
						<p className="text-center">OF COMPLETION</p>
					</header>
					<hr className="w-4 mx-auto border-b border-4 border-orange-500" />
					<p className="font-bold text-gray-900 text-2xl text-center tracking-widest">
						{certificate?.beneficiary.split(":")[1]}
					</p>
					<p className="text-center">
						{certificate?.desc
							? certificate?.desc
							: "successfully completed the"}
						<br />
						<span className="text-orange-500">
							{certificate?.program} program
						</span>
					</p>
					<footer className="grid grid-cols-12 gap-8 mt-5 w-full">
						<div className="col-span-5">
							{generateDate()}
							<hr className="w-32" />
							Date of Issuance
						</div>
						<div className="col-span-2 justify-self-center">
							<QRCodeSVG
								value={`https://edu.theablestate.com/certificates/${certificateId}/verify`}
								size={64}
							/>
						</div>
						<div className="col-span-5 justify-self-end">
							<img src={Signature} className="w-14" />
							<hr className="w-32" />
							Founder & CEO
						</div>
					</footer>
				</section>
			</article>
		</main>
	);
}
