import * as React from "react";
import { NavLink, useLocation, useLoaderData } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Form, ErrorMessage, Formik } from "formik";
import { organisationValidationSchema } from "../../../components/helpers/validator";
import { IoIosInformationCircleOutline } from "react-icons/io";
import TextAreaField from "../../../components/form/TextAreaField";
import TextInputField from "../../../components/form/TextInputField";
import ImageUpload from "../../../components/ui/ImageUpload";
import { MdOutlineAddAPhoto, MdOutlineClose } from "react-icons/md";
import api from "../../../components/helpers/appwrite/api";
import { Server } from "../../../components/helpers/appwrite/config";
import { Role, Permission } from "appwrite";
import { useAuth } from "../../../components/hooks/useAuth";
import { Appwrite } from "../../../services";
const appwrite = new Appwrite();

export const loader = async () => {
  const user = await appwrite.getAccount()
  return user ?? null
}

export default function Add() {
  const location = useLocation();
  const user = useLoaderData();
  console.log("User: ", user)
  const [activeLink, setActiveLink] = React.useState(location.pathname);
  const handleMouseEnter = (link) => {
    setActiveLink(link);
  };

  const handleMouseLeave = () => {
    setActiveLink(location.pathname);
  };
  const initialValues = {
    logo: "",
    logo_url: "",
    name: "",
    email: "",
    size: "",
    location: "",
    description: "",
    status: "dormant",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const {
        organisationCollectionID,
        databaseID,
        systemTeamID,
        logosBucketID,
      } = Server;

      const { logo_url, ...data } = values;
      const { $id: organisationId } = await api.createTeam(values.name);
      const permissions = [
        Permission.read(Role.any()),
        Permission.update(Role.team(systemTeamID)),
        Permission.delete(Role.team(systemTeamID)),
        Permission.update(Role.team(organisationId, "owner")),
        Permission.delete(Role.team(organisationId, "owner")),
      ];
      const { $id: logo } = await api.createOrganisationLogo(data.logo);
      data.logo = logo;
      const url = await api.getFileForView(logo, logosBucketID);
      data["logoUrl"] = url;
      data["members"] = [user.$id];
      data["creator"] = user.$id;
      const newOrganisation = await api.createDocument(
        databaseID,
        organisationCollectionID,
        data,
        organisationId,
        permissions
      );
      console.log("Newly created organisation", newOrganisation);
      resetForm({ values: initialValues });
      setSubmitting(false);
    } catch (error) {
      console.log("Error: ", error.message);
      console.log("Error Object: ", error);
      // resetForm({ values: initialValues });
      setSubmitting(false);
    }
    console.log("Values: ", values);
  };

  return (
    <div className="wrapper">
      <div className="content">
        <Helmet>
          <title>Add Organisation</title>
        </Helmet>
        <div className="flex">
          <NavLink
            exact
            to="/organisations"
            className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
              activeLink === "/organisations"
                ? "text-orange-500 border-b-2 border-orange-400"
                : "text-gray-800"
            }`}
            onMouseEnter={() => handleMouseEnter("/organisations")}
            onMouseLeave={handleMouseLeave}
          >
            All
          </NavLink>
          <NavLink
            to={`/organisations/new`}
            className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
              activeLink === `/organisations/new`
                ? "text-orange-500 border-b-2 border-orange-400"
                : "text-gray-800"
            }`}
            onMouseEnter={() => handleMouseEnter(`/organisations/new`)}
            onMouseLeave={handleMouseLeave}
          >
            Add New
          </NavLink>{" "}
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={organisationValidationSchema}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            return (
              <Form className="flex flex-col items-center">
                <h3 className="w-96 text-sm font-semibold">
                  Logo
                  <span className="text-sm font-semibold mx-1 text-red-700">
                    *
                  </span>
                </h3>
                <div className="relative w-96 h-24 mb-2">
                  <div className="absolute flex items-end justify-between w-11/12">
                    <div
                      className="bg-white w-20 h-20 md:w-28 md:h-28 rounded-full overflow-hidden bg-cover border-2 border-white"
                      style={{ backgroundImage: `url(${values?.logo_url})` }}
                    ></div>
                    <div className="bg-black bg-opacity-40 w-20 h-20 md:w-28 md:h-28 border-2 md:border-4 border-white rounded-full z-10 absolute top-0 left-0 flex gap-1 justify-center items-center text-white">
                      <div className="bg-black bg-opacity-80 hover:bg-opacity-70 text-white rounded-full p-2 cursor-pointer relative overflow-hidden">
                        <MdOutlineAddAPhoto size={20} />
                        <ImageUpload
                          fileRef="logo"
                          urlRef="logo_url"
                          inputStyles="absolute top-0 right-0 h-full w-full opacity-0 cursor-pointer"
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div
                        className="bg-black bg-opacity-80 hover:bg-opacity-70 text-white rounded-full p-3 cursor-pointer relative overflow-hidden"
                        onClick={(event) => {
                          event.preventDefault();
                          setFieldValue("logo", null);
                          setFieldValue("logo_url", null);
                        }}
                      >
                        <MdOutlineClose size={15} />
                      </div>
                    </div>
                  </div>
                </div>
                {errors?.logo_url && (
                  <div className="text-xs font-light text-red-700 w-96 text-left mt-2">
                    {errors?.logo_url}
                  </div>
                )}
                <TextInputField
                  label="Organisation name"
                  name="name"
                  id="name"
                  mandatory={true}
                />
                <TextInputField
                  label="Contact Email"
                  name="email"
                  id="email"
                  mandatory={true}
                />
                <div className="w-96 py-2">
                  <label className=" w-full text-sm text-black font-semibold">
                    Size of organisation
                    <span className="text-sm font-semibold mx-1 text-red-700">
                      *
                    </span>
                  </label>
                  <div className="relative flex items-center">
                    <select
                      name="size"
                      type="radio"
                      className={`w-full border py-1 rounded-md  text-sm focus:outline-none font-light px-2 appearance-none ${
                        errors?.size && touched?.size
                          ? "border-1 border-red-700 bg-red-100"
                          : "border-slate-200 bg-gray-100 focus:bg-white"
                      }`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={values?.size}
                    >
                      {/* To be fetched from the database */}
                      <option>-- select users --</option>
                      <option
                        defaultValue="small"
                        // selected={values?.size === "small"}
                      >
                        Small 1 - 900 users
                      </option>
                      <option
                        value="medium"
                        // selected={values?.size === "medium"}
                      >
                        Medium 900 - 1000 users
                      </option>
                      <option
                        defaultValue="large"
                        // selected={values?.size === "large"}
                      >
                        Large 1000 - 2000 users
                      </option>
                      <option
                        value="enterprise"
                        // selected={values?.size === "expertise"}
                      >
                        Enterprise 2000 or more
                      </option>
                    </select>
                    <div
                      className={`absolute right-0 h-fit w-fit pr-1 ${
                        errors?.size && touched?.size ? "visible" : "hidden"
                      }`}
                    >
                      <IoIosInformationCircleOutline
                        size={22}
                        className="text-red-700"
                      />
                    </div>
                  </div>
                  <ErrorMessage name="size">
                    {(msg) => (
                      <div className="text-xs font-light text-red-700">
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>
                <TextInputField
                  label="Location"
                  name="location"
                  id="location"
                />
                <TextAreaField
                  label="Description"
                  name="description"
                  id="description"
                />
                <button
                  className="w-96 py-2 rounded bg-orange-500 text-white text-center font-semibold uppercase text-sm mt-1 cursor-pointer focus:outline-none"
                  type="submit"
                  disabled={isSubmitting}
                >
                  submit
                </button>
                <button
                  value="debug"
                  onClick={(event) => {
                    event.preventDefault();
                    console.log("Values: ", values);
                    console.log("Errors: ", errors);
                  }}
                >
                  Debug
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
