import useForm from "../../features/programs/hooks/useForm";
import { useState, useEffect } from "react";

function Stepper() {
  const { activeStepIndex, setActiveStepIndex, initialValues } = useForm();

  useEffect(() => {
    setActiveStep(activeStepIndex);
  }, [activeStepIndex]);
  const steps =
    initialValues?.programType === "course"
      ? ["Details", "Modules", "Lessons", "Content"]
      : ["Details"];
  const [activeStep, setActiveStep] = useState(activeStepIndex);
  const handleMouseEnter = (index) => {
    setActiveStep(index);
  };

  const handleMouseLeave = () => {
    setActiveStep(activeStepIndex);
  };

  const handleClick = (index) => {
    setActiveStepIndex(() => index);
    console.log(initialValues);
  };

  return (
    <div className="w-32 h-full flex-col flex mr-2">
      {steps &&
        steps?.length > 0 &&
        steps.map((step, index) => (
          <div
            key={index}
            className={`text-sm inline-block py-1 px-2 hover:text-gray-900 transition-all duration-200 cursor-pointer pr-10 w-full ${
              activeStep === index
                ? "text-orange-500 border-l-2 border-orange-400 bg-orange-100"
                : "text-gray-800 border-l-2 border-l-transparent"
            }`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(index)}
          >
            <div className={activeStep === index ? "text-orange-500" : ""}>
              <span className="mr-1">{index + 1}.</span>
              {step}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Stepper;
