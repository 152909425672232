import { Outlet, redirect } from "react-router-dom";
import PrivateNav from "../../../../components/shared/PrivateNav";
import { Appwrite } from "../../../../services";
const appwrite = new Appwrite();

function AuthLayout() {
  return (
    <section className="bg-gray-50 light:text-gray-300 dark:bg-black dark:text-gray-300">
      <PrivateNav />
      <div className="container mx-auto min-h-screen">
        <Outlet />
      </div>
    </section>
  );
}

export const authLayoutLoader = async ({ request }) => {
  const pathname = new URL(request.url).pathname;
  console.log(pathname)
  console.log("authLayoutLoader")

  try {
    await appwrite.getAccount();
  } catch (error) {
    return redirect(`login?redirectTo=${pathname}`);
  }

  return null;
};

export default AuthLayout;
