import React from 'react'

function ManageTasks() {
  return (
      <div>
          <h1>ManageTasks</h1>
          <div>
              <h1>Fields</h1>
              Project title
              Description
              Attachments
              Sub task
                Title, Details, Team
              Due date
              Your Role
            Team
          </div>
      </div>

  )
}

export default ManageTasks