import Stepper from "../../../../components/form/Stepper";
import FormProvider from "../../../../components/providers/FormProvider";
import Step from "../../../programs/components/forms/add/Step";
export default function Add() {
  return (
    <div className="wrapper w-full">
      <div className="rounded-sm w-full h-full">
        <h2 className="text-lg text-gray-800 font-semibold">
          Create a new Program
        </h2>
        <FormProvider>
          <div className="flex mt-1">
            <Stepper />
            <Step />
          </div>
        </FormProvider>
      </div>
    </div>
  );
}
