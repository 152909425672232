import * as Yup from "yup";

export const organisationValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  size: Yup.string().required("Select the size of the organisation"),
  logo_url: Yup.string().test(
    "Logo required",
    "The logo is required",
    function (value) {
      if (!value) {
        this.createError({ path: "logo_url", message: "logo is required" });
        return false;
      }
      return true;
    }
  ),
  logo: Yup.mixed(),
});

export const programStepOneValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
//   thumbnailUrl: Yup.string().test(
//     "Thumbnail requirement",
//     "Thumbnail is required",
//     function (value) {
//       if (!value) {
//         this.createError({
//           path: "thumbnail",
//           message: "Thumbnail is required",
//         });
//         return false;
//       }
//       return true;
//     }
//   ),
  thumbnail: Yup.mixed().required("Thumbnail is required"),
//   description: Yup.string().required("Description is required"),
  programType: Yup.string().required("Program type is required"),
//   startDate: Yup.string().required("Start date is required"),
//   endDate: Yup.string().required("End date is required"),
});
