import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

function ProgramNav({ organisation }) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const handleMouseEnter = (link) => {
    setActiveLink(link);
  };

  const handleMouseLeave = () => {
    setActiveLink(location.pathname);
  };

  return (
    <div className="flex h-[1.85rem]">
      <div className="w-full h-[1.85rem]">
        <NavLink
          to="/organisations"
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink === "/organisations"
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() => handleMouseEnter("/organisations")}
          onMouseLeave={handleMouseLeave}
        >
          Browse Programs
        </NavLink>
        <NavLink
          to={`/programs`}
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink === `/programs`
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() =>
            handleMouseEnter(`/programs`)
          }
          onMouseLeave={handleMouseLeave}
        >
          All
        </NavLink>
        <NavLink
          to={`/programs/new`}
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink.startsWith(`/programs/new`)
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() =>
            handleMouseEnter(`/programs/new`)
          }
          onMouseLeave={handleMouseLeave}
        >
          Add program
        </NavLink>
        <NavLink
          to={`/programs/my-programs`}
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink.startsWith(`/organisations/my-programs`)
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() =>
            handleMouseEnter(`/programs/my-programs`)
          }
          onMouseLeave={handleMouseLeave}
        >
          My Programs
        </NavLink>
        <NavLink
          to={`/programs/enrolments`}
          className={`text-sm inline-block py-1 px-2 hover:text-gray-900 hover:border-gray-800 transition-all duration-200 ${
            activeLink.startsWith(`/programs/entolments`)
              ? "text-orange-500 border-b-2 border-orange-400"
              : "text-gray-800"
          }`}
          onMouseEnter={() =>
            handleMouseEnter(`/programs/enrolments`)
          }
          onMouseLeave={handleMouseLeave}
        >
          Enrolments
        </NavLink>
      </div>
      {/* <NavLink
        to={`/organisations/${organisation}/manage`}
        className="py-1 px-3 font-bold text-sm cursor-pointer border rounded-md border-black hover:text-white hover:bg-orange-500 hover:border-orange-500 duration-75 ease-in"
      >
        manage
      </NavLink> */}
    </div>
  );
}

export default ProgramNav;
