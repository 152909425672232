import { Await, Outlet, defer } from "react-router-dom";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";
import { Query } from "appwrite";
import { Appwrite } from "../../services";
const appwrite = new Appwrite();

function PrivateRoute({ allowedRoles }) {
  const data = useLoaderData();
  //   const userTeamMemberships = useLoaderData()
  //   const { user, setUserTeamMemberships } = useAuth();

  // useEffect(() => {
  //   if (user) {
  //     configureRoles();
  //   }
  //   return () => {};
  // }, [user]);

  // const configureRoles = async () => {
  //   const { teams } = await api.provider().teams.list();
  //   let userTeamMemberships = {};
  //   await Promise.all(
  //     teams.map(async (team) => {
  //       const {
  //         memberships: [membership],
  //       } = await api
  //         .provider()
  //         .teams.listMemberships(team.$id, [Query.equal("userId", user.$id)]);
  //       userTeamMemberships[`${team.$id}`] = membership;
  //     })
  //   );

  //   // console.log(teamMemberships);
  //   console.log("User Team Memberships: ", userTeamMemberships);
  //   // console.log("Teams: ", teams);
  //   setUserTeamMemberships(() => userTeamMemberships);
  // };

  return (
    // This is where I am supposed to take care of the role management
    // user?.user_metadata?.roles?.find((role) =>
    // allowedRoles?.includes(role)
    // user ? (
    // <Suspense fallback={<div>loading</div>}>
    //   <Await resolve={data.user}>
    //     {() => <Outlet/>}
    //   </Await>
    // </Suspense>
    <Outlet/>

    // ) : user ? (
    //   <Navigate to="/unathorized" />
    // ) : (
    //   <Navigate to="/login" state={{ from: location }} replace />
    // )
  );
  // )
}

export const loader = () => {
  const userPromise = appwrite.getAccount();
  return defer({
    user: userPromise,
  });
};

export default PrivateRoute;
