import { Link, useParams } from "react-router-dom";

export default function Details() {
	let { certificate } = useParams();

	return (
		<div className="wrapper">
			<div className="content">
				<Link to="/certificates">All</Link>
				<br />
				<Link to="/certificates/new">Add New</Link>
				<h1>Certifiaction {certificate}</h1>
			</div>
		</div>
	);
}
