import React from "react";
import { useParams } from "react-router-dom";
// import supabase from "../helpers/supabase";
const Verify = () => {
	const [loading, setLoading] = React.useState(true);
	const { certificate: certificateId } = useParams();
	const [certificate, setCertificate] = React.useState({});
	const [error, setError] = React.useState({});

	const fetchCertificate = async () => {
		// try {
		// 	const { data, error } = await supabase
		// 		.from("certificates")
		// 		.select("student_name")
		// 		.eq("code", certificateId);
		// 	setCertificate(data);
		// 	if (error) setError(error);
		// } catch (error) {
		// 	console.log(error);
		// }

		// setLoading(false);
	};

	React.useEffect(() => {
		fetchCertificate();
	}, []);

	if (loading) return <div>Checking</div>;
	if (error && !certificate) return <div>Verification failed</div>;
	return (
		<article className="w-full min-h-screen flex flex-col text-center items-center justify-center">
			<h1 className="text-green-500">Certificate is Valid</h1>
			Awarded To {certificate[0].student_name}
		</article>
	);
};

export default Verify;
