import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function List() {
    const location = useLocation()
    const [ programs, setPrograms ] = useState([])
    
    return (
      <div className="">
        <div className="">
        <ul>
          <li>
            <Link to={`${location.pathname}/new`}>Add New</Link>
          </li>
        </ul>
        <table>
          <thead>
            <tr>
              <th>
                ID
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Link to="/organisations/64789ea2b687229e7a44/programs/6989">6989</Link>
              </td>
              <td>
                <Link to="/organisations/9005/programs/6989/edit">Edit</Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/organisations/9005/programs/6090">6090</Link>
              </td>
              <td>
                <Link to="/organisations/9005/programs/6090/edit">Edit</Link>
              </td>
            </tr>
            <tr>
              <td>
                <Link to="/organisations/9005/programs/8767">8767</Link>
              </td>
              <td>
                <Link to="/organisations/9005/programs/8767/edit">Edit</Link>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    );
}