import Routing from "./Routing";
import { AppwriteProvider } from "./context/Appwrite";
function App() {
  return (
    <AppwriteProvider>
      <Routing />
    </AppwriteProvider>
  );
}

export default App;
