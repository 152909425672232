export const Server = {
    endpoint : process.env.REACT_APP_APPWRITE_API_ENDPOINT,
    project: process.env.REACT_APP_APPWRITE_API_PROJECT_ID,
    databaseID: process.env.REACT_APP_APPWRITE_DATABASE_ID,
    organisationCollectionID: process.env.REACT_APP_APPWRITE_ORG_COLLECTION_ID,
    systemTeamID: process.env.REACT_APP_APPWRITE_SYSTEM_TEAM_ID,
    logosBucketID: process.env.REACT_APP_APPWRITE_LOGOS_BUCKET_ID,
    resourcesBucketID: process.env.REACT_APP_APPWRITE_RESOURCES_BUCKET_ID,
    userMembershipListingFunctionID: process.env.REACT_APP_APPWRITE_USER_MEMBERSHIP_LISTING_FUNCTION_ID
    
}